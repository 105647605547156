import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Marquee3k from '../utils/marquee'

export default class ListPage extends React.Component {
  componentDidMount() {
    Marquee3k.init({
      selector: 'marquee',
    })
  }
  render() {
    const { data } = this.props
    const entries = data.feedEntries.edges
    return (
      <>
        <div className="list">
          <h1>Archive</h1>
          <div className="list__entries">
            {entries.filter(({ node }) => node.frontmatter.type === 'post').map(({ node: feedEntry }) => (
              <Link className="list__entry link link--nav" key={feedEntry.id} to={feedEntry.fields.slug}>
                <div className="list__entry__date">{feedEntry.frontmatter.date}</div>
                <div className="list__entry__title" to={feedEntry.fields.slug}>
                  <h4>{feedEntry.frontmatter.title}</h4>
                </div>
                <div className="list__entry__exc">{feedEntry.excerpt}</div>
              </Link>
            ))}
          </div>
        </div>
        <div className="marquee-container">
          <a
            href="https://www.patreon.com/morbidbooks"
            target="_blank"
            rel="noopener noreferrer"
            className="link link--marquee"
          >
            <div className="marquee" data-speed="0.35" data-reverse={false} data-pausable={true}>
              <span>Join the Temple of Surrealist Literature on Patreon... Subscribe now... Mind bending publications... Worldwide delivery</span>
            </div>
          </a>
        </div>
      </>
    )
  }
}

ListPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query ListQuery {
    feedEntries: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "feed-entry" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD/MM/YYYY")
            type
          }
        }
      }
    }
  }
`
